<script setup lang="ts">
import ThreeSection from "./ThreeSection.vue";
import FourthSection from "./FourthSection.vue";
import FiveAndSix from './FiveAndSix.vue'
import SeventhSection from './SeventhSection.vue'
import { createEffect3dBg, stopEffect3d, setPrecent } from '../../utilities/effect3d'
import { onMounted, onUnmounted, ref } from 'vue';
const gameBox = ref(null)
let init = false
onMounted(() => {
    window.addEventListener('scroll', onscroll)
    if (init || !gameBox.value) return
    init = true
    createEffect3dBg(gameBox.value as HTMLElement)
})
onUnmounted(() => {
    stopEffect3d()
    window.removeEventListener('scroll', onscroll)
})

const section = ref(null)
let timer: any;
const onscroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
        if (!section.value || !gameBox.value) {
            return
        }
        const sec = section.value as HTMLElement
        const box = gameBox.value as HTMLElement
        const secSize = sec.getBoundingClientRect()
        const boxSize = box.getBoundingClientRect()

        if (secSize.y > (-innerHeight)) {
            setPrecent(0)
            return
        }

        const scrollTop = document.scrollingElement?.scrollTop || document.body.scrollTop
        let precent = (scrollTop - sec.offsetTop) / (sec.offsetHeight - innerHeight * 2)
        if (precent > 1) precent = 1
        if (precent < 0) precent = 0
        precent = (precent - 0.25) / 0.75

        setPrecent(precent)
    })
}

</script>
<template>
    <div ref="section" class="relative">
        <div ref="gameBox" class='sticky left-0 top-0 mt-[-100svh] w-full h-screen z-[-1] overflow-hidden'></div>
        <div class="w-full effect-warp">
            <ThreeSection class=" lg:px-8" />
            <FourthSection class=" lg:px-8" />
            <FiveAndSix class=" lg:px-8" />
            <SeventhSection />
        </div>
    </div>
</template>
<style scoped>
.effect-warp {
    text-shadow: 1px 1px black;
}
@media screen and (max-width: 1280px) {
    .effect-warp {
        backdrop-filter: blur(4px);
        background-color: rgba(0,0,0,0.1);
    }
    
}
</style>