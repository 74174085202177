<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';


const roadMap = [
    {
        title: '2022 Q4',
        list: [
            'Conceptualized space-themed GameFi/DAO/NFT game',
            'Research tech for GameFi, NFTs, DAO integration',
            'Built a team of game, contract, NFT devs',
            'Initiated community building on social/crypto platforms'
        ]
    },
    {
        title: '2023 Q1',
        list: [
            'Prototyped core GameFi mechanics - NFT spaceships creation, trading, in-game use',
            'Developed game platform, smart contracts, NFT marketplace tech architecture',
        ]
    },
    {
        title: '2023 Q2',
        list: [
            'Designed DAO governance model for player participation',
            'Internal & UX testing, feedback integration, gameplay/performance optimization',
        ]
    },
    {
        title: '2023 Q3',
        list: [
            'Alpha with Star Voyager Medal NFT airdrop to early testers for data/feedback',
            'Partnered with GameFi projects, NFT markets, crypto exchanges for user growth',
        ]
    },
    {
        title: '2023 Q4',
        list: [
            'Ongoing community building - events, AMAs, competitions to boost engagement/loyalty',
        ]
    },
    {
        title: '2024 Q1',
        list: [
            'AstraOmniRise Beta - larger scale testing for scalability/stability',
            'Expanded content - quests, maps, ship customizations for richer gameplay',
            'Amplified marketing via social media, events, press for user/investor acquisition',
        ]
    },
    {
        title: '2024 Q2',
        list: [
            'Official global launch post-Beta',
            'Inaugural NFT pre-sale of unique spaceships with in-game utilities',
        ]
    },
    {
        title: '2024 Q3',
        list: [
            'Ongoing optimization - AstraOmniRise Game, Prologue Staking based on feedback/market demands',
            'Free PVP system launch, Space Arena, first full-scale Planet Testing',
        ]
    },
    {
        title: '2024 Q4',
        list: [
            'DAO governance enabling player participation in game development/operations',
            'Cross-platform expansion to mobile, VR, social media for user base growth',
            'Ecosystem building via partnerships for mutual GameFi/NFT industry growth, player benefits',
        ]
    },
    {
        title: '2025 Q1',
        list: [
            'Deeper partnerships with GameFi projects, NFT markets, DeFi for thriving ecosystems',
            'Enhanced community governance, player decision-making power',
        ]
    },
    {
        title: '2025 Q2',
        list: [
            'Emerge as leading GameFi/NFT project, delivering unique global gaming experiences',
            'Continuous innovation - new content, features, events for sustained ecosystem vitality',
        ]
    },
]
const w = roadMap.length * 12 + (roadMap.length - 1) * 2;
const min = -192
let timer: any;
const left = ref(-min)
const box = ref(null)
const section = ref(null)
const roadBox = ref(null)
const onscroll = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
        if (!box.value || !section.value || !roadBox.value) {
            return
        }
        const el = box.value as HTMLElement
        const sec = section.value as HTMLElement
        const road = roadBox.value as HTMLElement
        const boxSize = el.getBoundingClientRect()
        if (boxSize.y != 96) {
            return
        }

        const max = road.offsetWidth - window.innerWidth - min * 2
        const scrollTop = document.scrollingElement?.scrollTop || document.body.scrollTop
        const precent = (scrollTop - sec.offsetTop) / (sec.offsetHeight - innerHeight)
        left.value = max * precent * (-1) - min

    })
}
const date = new Date();
const isPass = (title: string) => {
    const [y, q] = title.split(' ')
    if (date.getFullYear().toString() != y) {
        return false
    }
    const qt = [1, 3]
    switch (q) {
        case 'Q2':
            qt[0] = 4
            qt[1] = 6
            break
        case 'Q3':
            qt[0] = 7
            qt[1] = 9
            break
        case 'Q4':
            qt[0] = 10
            qt[1] = 12
            break
    }
    const m = date.getMonth() + 1
    if (qt[0] <= m && m <= qt[1]) {
        return true
    }
    return false
}
onMounted(() => {
    window.addEventListener('scroll', onscroll)
})
onUnmounted(() => {
    window.removeEventListener('scroll', onscroll)
})

</script>
<template>
    <section ref="section" :style="{ height: `300vh` }">
        <div ref="box" :class="`home-page-section sticky top-24 flex flex-col justify-around bg-no-repeat max-xl:p-4`" style="background-image: url(/assets/images/home/section9-bg.svg) ; background-size: 50% ;
            background-position-x: 4rem">
            <h3 class="w-full font-orbitron text-4xl max-xl:text-2xl font-bold lg:px-8">
                <p class="max-w-7xl mx-auto">Roadmap</p>
            </h3>
            <div class="border-t-[1px] border-[#8A8A8A] w-full overflow-x-clip">
                <div ref="roadBox" class="flex gap-8 mt-6"
                    :style="{ width: `${w}rem`, transform: `translateX(${left}px)` }">

                    <div v-for="m in roadMap" class="w-48 relative" :key="m.title">
                        <img v-show="isPass(m.title)"
                            class="w-full absolute left-0 -top-6 translate-y-[-100%] translate-x-[-50%]"
                            src="/assets/images/home/section9-road-marker.png" alt="marker" />
                        <svg class="absolute left-0 -top-6 translate-y-[-50%]" xmlns="http://www.w3.org/2000/svg" width="12"
                            height="12" viewBox="0 0 12 12" fill="none">
                            <circle cx="5.5" cy="5.5" r="5.5" fill="#1B1B1B" stroke="white" />
                        </svg>
                        <h4 class="font-orbitron text-2xl font-bold mb-8">{{m.title}}</h4>
                        <p v-for="l in m.list" :key="l" class="text-[#E1E1E1] my-2 leading-6">{{ l }}</p>
                    </div>
                </div>
            </div>
            <div class="w-full">
                <p class="max-w-5xl mx-auto text-[#8A8A8A] flex items-center gap-2">
                    <span>Scroll</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16" fill="none">
                        <path
                            d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0 9H24V7H0V9Z"
                            fill="#8A8A8A" />
                    </svg>
                </p>
            </div>
        </div>
    </section>
</template>