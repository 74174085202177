<script setup lang="ts">
const date = [
  {
    name: "Abel Davis",
    position: "Concept Artist",
    img: "/assets/images/home/team/img--Abel-Davis.jpg",
    desc: "Senior game planner with 10 years of game experience. Graduated from MIT. Team leader of the game R&D centre, and participated in games such as Here Comes Paradise.In 2019, he founded an independent game studio, served as CEO, and led the development of the SLG game \"Dragon's Domination\".",
  },
  {
    name: "Lena Meyer",
    position: "3D Designer",
    img: "/assets/images/home/team/img--Lena-Meyer.jpg",
    desc: "8 years of experience in the game industry. Work for large game companies, a producer of PERSONALY series of games, and a participant in the production of MMORPG Dragon's Soul, TV commercials TV programmes, short films and print campaigns.",
  },
  {
    name: "Yang Dae-won",
    position: "Technical Art",
    img: "/assets/images/home/team/img--Yang-Dae-won.jpg",
    desc: "Over 10+ years of experience in the field. Specialises in creating high-quality real-time scenes and character models, textures and shaders. Participated in the development of the adventure game codenamed \"Ark Sea\".",
  },
  {
    name: "Robert",
    position: "CREATIVE DIRECTOR",
    img: "/assets/images/home/team/img--Robert.jpg",
    desc: "12 years of game experience, graduated from Stanford University, specialising in building the framework of game worldviews and transforming them into series of IP operations. His representative works include the new IP \"Legend of the Clouds\" and the RPG \"Magic Cube Labyrinth\".",
  },
];
</script>
<template>
  <section>
    <div class="max-w-7xl mx-auto lg:px-8 flex flex-col justify-center max-xl:text-2xl">
      <h3 class="text-3xl max-xl:text-2xl font-orbitron font-bold text-center mb-12">
        Meet Our Team
      </h3>
      <div class="flex gap-5 mt-10 max-xl:flex-col max-xl:justify-around max-xl:items-center flex-wrap">
        <div v-for="data in date" :key="data.name"
          class="team-item relative w-full rounded-lg border-[1px] border-[#8A8A8A]">
          <div class="p-10">
            <h4 class="font-orbitron text-2xl font-bold">{{ data.name }}</h4>
            <p class="text-xl font-bold">{{ data.position }}</p>
            <div class="mt-5">
              <img class="team-img absolute left-0 top-0 z-0 w-full" :src="data.img" :alt="data.name" />
              <p class="team-desc text-sm leading-7 text-gray-400">
                {{ data.desc }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="w-full mt-12" src="/assets/images/home/section10-bottom-mask.svg" alt="mask" />
  </section>
</template>