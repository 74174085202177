import * as THREE from 'three';

import Stats from 'three/addons/libs/stats.module.js';

import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { BloomPass } from 'three/addons/postprocessing/BloomPass.js';
import { FilmPass } from 'three/addons/postprocessing/FilmPass.js';
import { FocusShader } from 'three/addons/shaders/FocusShader.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
import { FBXLoader, UnrealBloomPass } from 'three/examples/jsm/Addons.js';

let camera: THREE.PerspectiveCamera, scene: THREE.Scene, renderer: THREE.WebGLRenderer, mesh: THREE.Points;

let parent: THREE.Object3D;
let stopEffect = false
let mouseX = 0, mouseY = 0;
let composer: EffectComposer, effectFocus: ShaderPass;
const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;
let initialPosition: THREE.BufferAttribute;
let initialPositionA: THREE.BufferAttribute;
let initialPositionB: THREE.BufferAttribute;
const clock = new THREE.Clock();
const raycaster = new THREE.Raycaster();
const PARTICLE_SIZE = 20;
let stats: Stats;
export function createEffect3dBg(box: HTMLElement) {
    stopEffect = true
    init(box);
    animate();
    window.document.addEventListener('pointermove', onPointerMove)
}
async function init(box: HTMLElement) {

    const container = box;

    camera = new THREE.PerspectiveCamera(20, window.innerWidth / window.innerHeight, 1, 50000);
    camera.position.set(0, 0, 7000);

    scene = new THREE.Scene();
    // scene.background = new THREE.Color(0x000104);
    scene.fog = new THREE.FogExp2(0x000104, 0.0000675);

    camera.lookAt(new THREE.Vector3(0, 0, 0));

    const loader = new FBXLoader();

    renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.autoClear = false;
    container.appendChild(renderer.domElement);

    parent = new THREE.Object3D();
    

    // const grid = new THREE.Points(new THREE.PlaneGeometry(15000, 15000, 64, 64), new THREE.PointsMaterial({ color: 0xff0000, size: 10 }));
    // grid.position.y = - 400;
    // grid.rotation.x = - Math.PI / 2;
    // parent.add(grid);

    scene.add(parent);
    // postprocessing

    const renderModel = new RenderPass(scene, camera);
    const effectBloom = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 0.7,0.1, 0.3 );
    
    const effectFilm = new FilmPass();

    effectFocus = new ShaderPass(FocusShader);

    effectFocus.uniforms['screenWidth'].value = window.innerWidth * window.devicePixelRatio;
    effectFocus.uniforms['screenHeight'].value = window.innerHeight * window.devicePixelRatio;

    const outputPass = new OutputPass();

    composer = new EffectComposer(renderer);

    composer.addPass(renderModel);
    composer.addPass(effectBloom);
    composer.addPass(effectFilm);
    composer.addPass(effectFocus);
    composer.addPass(outputPass);

    //stats
    stats = new Stats();
    container.appendChild(stats.dom);

    window.addEventListener('resize', onWindowResize);

    // const ma = await loader.loadAsync('/assets/model/Wonder.fbx')
    const mb = await loader.loadAsync('/assets/model/Butterfly.fbx')

    // initialPositionA = combineBuffer(ma, 'position');
    initialPositionB = combineBuffer(mb, 'position');
    initialPositionA = randomPosition(initialPositionB);
    createMesh(initialPositionA, 0.2, - 500, -350, 600, 0xff7744);

}
function onPointerMove(event: any) {

    if (event.isPrimary === false) return;

    mouseX = event.clientX - windowHalfX;
    mouseY = event.clientY - windowHalfY;

    // raycaster.setFromCamera(new THREE.Vector2(mouseX, mouseY), camera);

    // const intersects = raycaster.intersectObject(mesh, true);
    // if(intersects.length>0){
    //     mesh.attributes.size.array[ INTERSECTED ] = PARTICLE_SIZE * 1.25;
	// 	attributes.size.needsUpdate = true;
    // }
}

function onWindowResize() {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    camera.lookAt(scene.position);

    renderer.setSize(window.innerWidth, window.innerHeight);
    composer.setSize(window.innerWidth, window.innerHeight);

    effectFocus.uniforms['screenWidth'].value = window.innerWidth * window.devicePixelRatio;
    effectFocus.uniforms['screenHeight'].value = window.innerHeight * window.devicePixelRatio;

}

function combineBuffer(model: THREE.Group<any>, bufferName: string) {

    let count = 0;
    model.traverse((child: any) => {

        if (child.isMesh) {

            const buffer = child.geometry.attributes[bufferName];

            count += buffer.array.length;

        }

    });

    const combined = new Float32Array(count);

    let offset = 0;

    model.traverse(function (child: any) {

        if (child.isMesh && child.visible) {

            const buffer = child.geometry.attributes[bufferName];

            combined.set(buffer.array, offset);
            offset += buffer.array.length;

        }

    });

    return new THREE.BufferAttribute(combined, 3);

}

function createMesh(positions: THREE.BufferAttribute, scale: number, x: number, y: number, z: number, color: number) {

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', positions.clone());

    const c = color;

    mesh = new THREE.Points(geometry, new THREE.PointsMaterial({ size: PARTICLE_SIZE, color: c }));
    mesh.scale.x = mesh.scale.y = mesh.scale.z = scale;

    mesh.position.x = x;
    mesh.position.y = y;
    mesh.position.z = z;
    mesh.rotateZ(-Math.PI);
    parent.add(mesh);


}

function randomPosition(data: THREE.BufferAttribute) {
    const temp = data.clone()
    const len = data.count;
    for (let i = 0; i < len; i++) {

        const radius = innerWidth*0.95*0.5*10
        let rand = Math.random()
        rand = (rand*10)%2==0?rand:1-rand
        const theta = rand * 2 * Math.PI;
        const phi = Math.acos(2 * Math.random() - 1);

        const x = 0 + radius * Math.sin(phi) * Math.cos(theta);
        const y = 0 + radius * Math.sin(phi) * Math.sin(theta);
        const z = 0 + radius * Math.cos(phi);
        
        temp.setXYZ(i, x, y, z)
    }
    return temp
}

function animate() {
    stopEffect = false
    requestAnimationFrame(animate);
    render();
    stats.update();
}

export function stopEffect3d() {
    stopEffect = true
}
let scale = 0.2
export function setPrecent(precent: number) {
    if (!mesh || stopEffect) {
        return
    }
    let curPrecent = precent + 0
    const data = mesh.geometry.attributes.position
    const count = data.count;

    if (precent <= 0.55) {
        curPrecent = precent / 0.55
        scale = 0.2
    } else {
        curPrecent = 1
        const s = 1-(precent - 0.55) / (1-0.55)
        scale = 0.05 + (0.2 - 0.05) * s

    }
    
    for (let i = 0; i < count; i++) {

        let x = 0, y = 0, z = 0, rx = 0, ry = 0, rz = 0;

        x = initialPositionA.getX(i);
        y = initialPositionA.getY(i);
        z = initialPositionA.getZ(i);
        rx = initialPositionB.getX(i);
        ry = initialPositionB.getY(i);
        rz = initialPositionB.getZ(i);
        const next = new THREE.Vector3(x, y, z).lerp(new THREE.Vector3(rx, ry, rz), curPrecent);
        data.setXYZ(i, next.x, next.y, next.z);

    }
    
    mesh.scale.setScalar(scale)
    data.needsUpdate = true;
}
function render() {
    if (!mesh || stopEffect) {
        return
    }

    camera.position.x += (mouseX - camera.position.x) * 0.08;
    camera.position.y += (- mouseY - camera.position.y) * 0.08;

    camera.lookAt(mesh.position);

    let delta = 10 * clock.getDelta();

    delta = delta < 2 ? delta : 2;

    mesh.rotation.y += - 0.005 * delta;

    composer.render(delta);
    // renderer.render(scene, camera);

}