<script setup lang="ts">
const { t } = window.I18nClient
</script>
<template>
    <section class=" max-xl:px-4">
        <div class="home-page-section max-w-7xl mx-auto flex flex-row gap-16 items-start max-xl:flex-col">
            <h3 class="w-1/2 max-xl:w-full font-orbitron text-4xl max-xl:text-3xl leading-10 font-bold">{{t('home.section5.title')}}</h3>
            <div class="w-1/2 max-xl:w-full text-xl leading-8">
                <p class="mb-5">{{t('home.section5.p1')}}</p>
                <p>{{t('home.section5.p2')}}</p>
            </div>
        </div>
        <div class="home-page-section max-xl:mt-0 max-w-7xl mx-auto flex flex-col justify-center">
            <p class="w-1/3 max-xl:w-full bg-[#242424b8] px-10 py-10 rounded-lg text-xl leading-8 mb-20">{{t('home.section6.content')}}</p>
            <div class="w-1/3 max-xl:w-full">
                <p class="mb-4">
                    <span class="w-5 h-5 inline-block text-center leading-5 border-[1px] border-[#E1E1E1] text-sm">1</span>
                    <span class="ml-2">{{t('home.section6.step1')}}</span>
                </p>
                <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                    <path d="M5 0V12M5 12L1 7.83333M5 12L9 7.83333" stroke="#E1E1E1" />
                </svg>
                <p class="my-4">
                    <span class="w-5 h-5 inline-block text-center leading-5 border-[1px] border-[#E1E1E1] text-sm">2</span>
                    <span class="ml-2">{{t('home.section6.step2')}}</span>
                </p>
                <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                    <path d="M5 0V12M5 12L1 7.83333M5 12L9 7.83333" stroke="#E1E1E1" />
                </svg>
                <p class="my-4">
                    <span class="w-5 h-5 inline-block text-center leading-5 border-[1px] border-[#E1E1E1] text-sm">3</span>
                    <span class="ml-2">{{t('home.section6.step3')}}</span>
                </p>
                <svg class="ml-1" xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 10 13" fill="none">
                    <path d="M5 0V12M5 12L1 7.83333M5 12L9 7.83333" stroke="#E1E1E1" />
                </svg>
            </div>
        </div>
    </section>
</template>