
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

const { t } = window.I18nClient
const section = ref<HTMLElement | undefined>(undefined)
const classes = ref('')
const humanClass = ref(false)
let timer: any;
const onscroll = () => {

  clearTimeout(timer);
  timer = setTimeout(() => {
    if (!section.value) {
      return
    }
    const rect = section.value.getBoundingClientRect()
    if (rect.y <= innerHeight / 2) {
      classes.value = 'animate__fadeInUp'
      humanClass.value = true
    } else if (rect.y > 0) {
      classes.value = '';
      humanClass.value = false
    }
  })
}
onMounted(() => {
  window.addEventListener('scroll', onscroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', onscroll)
})

</script>

<template>
  <section ref="section" class="h-screen w-full section-2 bg-no-repeat bg-cover relative"
    style="background-image: url(/assets/images/home/section2-bg.jpg)">
    <div class="bottom-mask absolute left-0 top-0 h-24 w-full"
      style="background: linear-gradient(0deg, rgba(5, 5, 8, 0) 0%, #050508 100%)"></div>
    <img class="absolute bottom-0 transition-all" :style="{transitionDuration: '0.7s',transform: `translateX(${humanClass ? 0 : '-100%'})`}" src="/assets/images/home/section2-human.png" alt="human">
    <div class="flex justify-end items-center h-full max-w-7xl mx-auto">
      <div :class="`w-1/2 animate__animated opacity-0 max-xl:w-full max-xl:px-4 ${classes}`">
        <h3 class="text-[2rem] font-orbitron mb-20 font-bold max-xl:text-2xl">
          {{ t("home.section2.title") }}
        </h3>
        <p class="mb-5">{{ t("home.section2.p1") }}</p>
        <p>{{ t("home.section2.p2") }}</p>
      </div>
    </div>
    <img class="absolute bottom-0 right-0 w-full" src="/assets/images/home/section2-bottom.png" alt="bottom">
  </section>
</template>