<script setup lang="ts">
function showVideo() {
    const trailerVideo = document.querySelector("#trailer-video") as HTMLElement;
    const video = document.querySelector("#trailer-video video") as HTMLVideoElement;
    let canPlay = false;
    if (trailerVideo && video) {

        trailerVideo.style.display = 'flex';
        video.play();
        trailerVideo.addEventListener('click', (e: MouseEvent) => {
            if (e.target == video) {
                return
            }
            e.stopPropagation();
            trailerVideo.style.display = 'none';
            !video.paused && video.pause();
        })
        trailerVideo.addEventListener('wheel', (e: WheelEvent) => {
            e.preventDefault()
        })
    }
}

</script>
<template>
    <div class="inline-block" @click="showVideo">
        <slot></slot>
    </div>
</template>