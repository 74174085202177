<template>
    <section class="home-page-section bg-cover bg-no-repeat max-xl:p-4" style="backgroundImage:url(/assets/images/home/section8-bg.svg)">
    <div class="max-w-7xl mx-auto">
        <h1 class="text-4xl max-xl:text-2xl font-bold font-orbitron">Token</h1>
        <img class="w-full" src="/assets/images/home/section8-token.png" alt="token"/>
        
    </div>
</section>
</template>
<style scoped>
@media screen and (max-width: 768px) {
    .home-page-section {
        min-height: auto;
        height: auto;
    }
    
}
</style>