<script setup lang="ts">
import { ref } from "vue";

const { t } = window.I18nClient
const data = ['I', 'II', 'III']
const phases = [1, 2, 3, 4]
const index = ref(0)
const nextIndex = () => {
    // if(window.innerWidth < 1280) return
    if (index.value === data.length - 1) {
        index.value = 0
    } else {
        index.value = index.value + 1
    }
}
</script>

<template>
    <section class="home-page-section overflow-hidden relative" @click="nextIndex">
        <div class="absolute max-xl:static left-0 top-0 h-full whitespace-nowrap flex flex-row transition-all"
            :style="{ width: `${data.length}00vw`, transform: `translateX(-${index}00vw)` }">
            <div v-for="(d, i) in data" class="w-screen whitespace-normal h-full bg-cover" :key="i" :style="{
                backgroundImage: `url(/assets/images/home/Phase${i}.jpg)`
            }">
                <div class="max-w-7xl lg:px-8 mx-auto h-full flex flex-row items-center max-xl:flex-col max-xl:p-4">
                    <div class="flex flex-row items-stretch max-xl:flex-col">
                        <div class="w-1/2 pr-16 max-xl:pr-0 flex flex-col justify-between items-start max-xl:w-full">
                            <div>
                                <p class="flex flex-row items-center gap-3 text-xl mb-10">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="2" viewBox="0 0 80 2"
                                        fill="none">
                                        <path d="M0 1H80" stroke="white" strokeWidth="2" />
                                    </svg>
                                    <span>Phase {{ d }}</span>
                                </p>
                                <h3 class="font-orbitron text-3xl max-xl:text-2xl leading-[3.2rem] font-bold">{{ t(`home.section7.phase${i +
                                    1}.title`) }}</h3>
                            </div>
                            <div type="button"
                                class="section7-next-btn flex gap-3 text-2xl font-bold select-none hover:text-[#E68F1B] max-xl:my-4 max-xl:hidden">
                                <span>{{ i + 1 }}/{{ data.length }}</span>
                                <span>NEXT</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="20" viewBox="0 0 56 20"
                                    fill="none">
                                    <path d="M4 14H48L40 6" stroke="white" strokeWidth="3" />
                                </svg>
                            </div>
                        </div>
                        <div class="w-1/2 grid grid-cols-2 gap-8 max-xl:w-full max-xl:grid-cols-1">
                            <div v-for="dd in phases" :key="dd" class=" rounded-lg p-6" style="
                                background: rgba(0, 0, 0, 0.32); backdrop-filter: blur(8px);
                                box-shadow: -1px 0px 0 rgba(157,157,157 ,61%)
                            ">
                                <h3 class="text-xl font-bold">{{ t(`home.section7.phase${i + 1}.item${dd}.title`) }}</h3>
                                <p class="mt-4 leading-6">{{ t(`home.section7.phase${i + 1}.item${dd}.content`) }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img class="absolute left-0 bottom-0 w-screen" src="/assets/images/home/selecttion7-footer-mask.svg"
            alt="footer-mask" />
    </section>
</template>
<style scoped>
.home-page-section{
    cursor: url(/assets/images/home/right-cur.png), auto;
}
</style>