import { useEffect, useRef, useState } from "react";
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import AsyncVideo from '../vue/AsyncVideo.vue'
const { t } = window.I18nClient
const section = ref < HTMLElement | undefined > (undefined)
const classes = ref('')
let timer: any;
const onscroll = () => {

  clearTimeout(timer);
  timer = setTimeout(() => {
    if (!section.value) {
      return
    }
    const rect = section.value.getBoundingClientRect()
    if (rect.y <= innerHeight / 2) {
      classes.value = 'animate__fadeInUp'
    } else if (rect.y > 0) {
      classes.value = ''
    }
  })
}
onMounted(() => {
  window.addEventListener('scroll', onscroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', onscroll)
})
</script>
<template>
  <section ref="section" class="home-page-section w-full flex flex-row items-center max-xl:px-4">
    <div
      class="w-full flex flex-row items-center max-w-7xl mx-auto gap-20 justify-between border-b border-[#8A8A8A] pb-20 max-xl:flex-col">
      <div :class="`w-1/3 animate__animated opacity-0 max-xl:w-full ${classes}`">
        <h3 class="font-orbitron text-3xl font-bold">{{ t("home.section3.title") }}</h3>
        <p class="mt-20 mb-6 text-xl max-xl:my-4" v-html="t('home.section3.p1')"></p>
        <p class="text-xl">{{ t("home.section3.p2") }}</p>
      </div>
      <div class="w-2/3 relative viewer bg-[#212121] max-xl:w-full" style="aspectRatio: 42/24">
        <svg class="absolute top-0 left-0" xmlns="http://www.w3.org/2000/svg" width="107" height="35" viewBox="0 0 107 35"
          fill="none">
          <path d="M71 1H1V35M84.5 1L107 1" stroke="white"></path>
        </svg>
        <svg class="absolute bottom-0 right-0" xmlns="http://www.w3.org/2000/svg" width="106" height="2"
          viewBox="0 0 106 2" fill="none">
          <path d="M106 1L36 1.00001M22.5 1.00001L0 1.00001" stroke="white"></path>
        </svg>
        <AsyncVideo class="object-cover w-full h-full" :muted="true" :autoplay="true" :loop="true" poster="/assets/images/home/section3-video-poster.jpg" src="https://cdn.playaor.com/common/astra-web/videos/1702526750.mp4"/>
      </div>
    </div>

  </section>
</template>
<style scoped>
.home-page-section {
    margin-top: 0;
}
</style>