<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AsyncVideo from '../vue/AsyncVideo.vue'
const { t } = window.I18nClient
const box = ref(null)
    const block = ref(null)
    const scale = ref(1)
    onMounted(()=>{
        if (!box.value || !block.value) {
            return
        }
        const boxSize = (box.value as HTMLElement).getBoundingClientRect()
        const blockSize = (block.value as HTMLElement).getBoundingClientRect()
        scale.value = boxSize.width / blockSize.width
    })
</script>
<template>
    <section class="home-page-section flex flex-row items-center max-w-7xl mx-auto max-xl:px-4 max-xl:flex-col">
        <div ref="box" class="w-2/2 overflow-hidden bg-left max-xl:hidden" style="background-image: url(/assets/images/home/section4-bg.svg);">
            <div ref="block" class="w-[46rem] section4-block-bg h-[33rem] relative" :style="`transform: scale(${scale}), transformOrigin: left`">
                <div class="w-[27.3rem] h-full absolute">
                    <img class="w-full h-full" src="/assets/images/home/section4-border1.svg" alt="" />
                </div>
                <div class="w-[27rem] h-full absolute translate-x-[60%]">
                    <img class="w-full h-full" src="/assets/images/home/section4-border2.svg" alt="" />
                </div>
            </div>
        </div>
        <div class="w-1/3 pl-8 max-xl:w-full max-xl:pl-0 max-xl:mt-4">
            <div class="bg-gray-700 mb-12">
                <AsyncVideo class="object-cover" :muted="true" :autoplay="false" :controls="true" :loop="false" poster="/assets/images/home/aor-section4-video-poster.jpg"
                 src="https://cdn.playaor.com/common/astra-web/videos/1702289154.mp4"/>
            </div>
            <div class="text-xl">
                <p>{{t('home.section4.p1')}}</p>
                <p class="my-4">{{t('home.section4.p2')}}</p>
                <p>{{t('home.section4.p3')}}</p>
            </div>
        </div>
    </section>
</template>