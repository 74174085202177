<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps<{
    src: string
    poster?: string
    autoplay?: boolean
    muted?: boolean
    loop?: boolean
    controls?: boolean
}>()
const box = ref<HTMLDivElement>()
const emit = defineEmits(['canplay'])

onMounted(() => {

    if (!box.value) {
        return
    }

    const video = document.createElement('video');
    box.value?.appendChild(video);
    video.src = props.src;
    video.className = 'object-cover w-full h-full';
    video.loop = props.loop;
    video.muted = props.muted == undefined ? true : props.muted;
    video.autoplay = props.autoplay;
    video.controls = props.controls;
    video.preload = 'none';

    video.oncanplay = () => {
        emit('canplay')
        if (props.autoplay === true) {
            video.volume = 0
            video?.play()
        }
    }
})

</script>

<template>
    <div ref="box" class="async-video">
    </div>
</template>