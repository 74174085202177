
<script setup lang="ts">
import EffectBgSetion from "./EffectBgSetion.vue";
import EighthSction from "./EighthSction.vue";
import FirstSection from "./FirstSection.vue";

import NinthSction from "./NinthSction.vue";
import SecondSection from "./SecondSection.vue";
import TenthSection from "./TenthSection.vue";

</script>
<template>
    <FirstSection/>
    <SecondSection />
   
    <EffectBgSetion/>
    <EighthSction class=" lg:px-8"/>
    <NinthSction/>
    <TenthSection/>
</template>